import React from "react"
import Header from "../header"
import "../layout.css"
import SEO from '../../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faUsers, faList, faLevelUpAlt } from "@fortawesome/free-solid-svg-icons"

const Recipe = ({ pageContext }) => {
  const getRecipeSteps = () => {
    return pageContext.steps.map((step, index) => {
      return (
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 detail-step pb-2" key={index}>
          <h4 className="step-heading">Step {index + 1}</h4>
          <p itemProp="recipeInstructions">{step.title}</p>
          <img src={step.image} width="100%" />
        </div>);
    });
  }

  const getIngredientGroups = () => {
    return pageContext.ingredients.map((ingredientGroup, index) => {
      return (<div className="col-6" key={index}>
        {ingredientGroup.purpose && (
          <>
            <h6>{ingredientGroup.purpose}</h6>
            <ul>
              {ingredientGroup.items.map((ingredient) => (<li key={ingredient} itemProp="recipeIngredient">{ingredient}</li>))}
            </ul>
          </>
        )}
      </div>);
    })
  }

  const getCookTime = (cookTimeStr) => {
    const regexToGetCookTimeFromString = new RegExp(/^([0-9]+ [a-zA-Z])/)
    try {
      const matches = cookTimeStr.match(regexToGetCookTimeFromString);
      const [firstMatch] = matches;
      return firstMatch.toUpperCase().replace(' ', '');
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  return (
    <>
      <Header siteTitle={pageContext.title} />
      <SEO title={pageContext.title} />
      <div className="container" itemScope itemType="https://schema.org/Recipe">
        <div className="recipe-detail-wrap">
          <h1 itemProp="name">{pageContext.title}</h1>
          <p className="recipe-description" itemProp="description">{pageContext.description}</p>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-2">
              <div className="main-image-wrap">
                <img itemProp="image" className="recipe-main-image" src={pageContext.mainImageUrl} width="100%" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="recipe-ingredients">
                <h1>Ingredients</h1>
                <div className="row">
                  {getIngredientGroups()}
                </div>
              </div>
            </div>
          </div>
          <div className="row recipe-key-facts">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 key-fact">
              <h3 itemProp="cookTime" content={`PT${getCookTime(pageContext.totalCookingTime)}`}><span className="heading-icon"><FontAwesomeIcon icon={faUtensils} /></span>Cooking Time</h3>
              {pageContext.totalCookingTime}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 key-fact key-fact">
              <h3><span className="heading-icon"><FontAwesomeIcon icon={faUsers} /></span>Serves</h3>
              {pageContext.numberOfServings}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 key-fact key-fact">
              <h3><span className="heading-icon"><FontAwesomeIcon icon={faList} /></span>Total Steps</h3>
              {pageContext.steps.length}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 key-fact key-fact">
              <h3><span className="heading-icon"><FontAwesomeIcon icon={faLevelUpAlt} /></span>Difficulty</h3>
              Easy
            </div>
          </div>
          <div className="row recipe-steps">
            <div className="col">
              <h1>Directions / steps to prepare:</h1>
              <div className="row">
                {getRecipeSteps()}
              </div>
            </div>
          </div>
          <div className="row recipe-video">
            <div className="col">
              <h1>Video Instructions</h1>
              <iframe width="100%" height="315" src={`https://www.youtube.com/embed/${pageContext.youtubeVideoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Recipe.propTypes = {
}

export default Recipe
